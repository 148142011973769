import React from 'react';
import PropTypes from 'prop-types';

const EventDetail = (props) => {
	return <div>EventDetail</div>;
};

EventDetail.propTypes = {};

export default EventDetail;

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_CONFIRMED = 'REGISTER_CONFIRMED';
export const REGISTER_DENIED = 'REGISTER_DENIED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_DENIED = 'AUTH_DENIED';
export const LOGIN_CONFIRMED = 'LOGIN_CONFIRMED';
export const LOGIN_DENIED = 'LOGIN_DENIED';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ADD_POST = 'ADD_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const EDIT_POST = 'EDIT_POST';
export const POST_ERROR = 'POST_ERROR';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_GIG = 'ADD_GIG';
export const EDIT_GIG = 'EDIT_GIG';
export const GIG_ERROR = 'GIG_ERROR';
export const GET_GIGS = 'GET_GIGS';
export const GET_GIG = 'GET_GIG';
export const DELETE_GIG = 'DELETE_GIG';
export const SET_FORM = 'SET_SIGNUP';
export const HANDLE_COMMENT = 'HANDLE_COMMENT';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const CLEAR_FORM = 'CLEAR_FORM';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const FORM_ERROR = 'FORM_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_TAGS = 'SET_TAGS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_POPUP = 'SET_POPUP';
export const CLEAR_LOCATION_RESULTS = 'CLEAR_LOCATION_RESULTS';
export const SET_DISCOVER_VALUE = 'SET_DISCOVER_VALUE';
export const SET_DISCOVER_ERROR = 'SET_DISCOVER_ERROR';
export const CLEAR_DISCOVER = 'CLEAR_DISCOVER';
export const EDIT_USER_FIELD = 'EDIT_USER_FIELD';
export const CHECK_UNIQUE_USER_FIELD = 'CHECK_UNIQUE_USER_FIELD';
export const CLEAR_TAGS = 'CLEAR_TAGS ';
export const SET_FEED = 'SET_FEED';
export const EDIT_FEED_ITEM = 'EDIT_FEED_ITEM';
export const ADD_FEED_ITEM = 'ADD_FEED_ITEM';
export const SET_MESSAGES = 'SET_MESSAGES';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_EVENT = 'SET_EVENT';
export const SET_EVENT_BY_ID = 'SET_EVENT_BY_ID';
export const EDIT_THREADS = 'EDIT_THREADS';

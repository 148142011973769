export const dashboardButtonData = [
	// {
	// 	index: 1,
	// 	itemReference: 'notification',
	// 	window: 'notifications',
	// 	path: null,
	// 	classNames: 'dashboard-btn',
	// 	icon: 'far dashboard-icon fa-bell',
	// 	buttonText: 'Notifications',
	// },
	{
		index: 2,
		window: 'message-board',
		path: '/messages',
		classNames: 'dashboard-btn',
		itemReference: 'message',
		icon: 'fas dashboard-icon fa-envelope-open',
		buttonText: 'Messages',
	},
	// {
	// 	index: 3,
	// 	window: 'event-browse',
	// 	path: '/event',
	// 	classNames: 'dashboard-btn',
	// 	icon: 'fas dashboard-icon fa-calendar-alt',
	// 	buttonText: 'Events',
	// },
	{
		index: 4,
		window: 'gig-browse',
		path: '/gig',
		classNames: 'dashboard-btn',
		icon: 'fas dashboard-icon fa-money-bill-alt',
		buttonText: 'Gigs',
	},
	// {
	// 	index: 5,
	// 	window: 'following',
	// 	path: null,
	// 	classNames: 'dashboard-btn',
	// 	icon: 'fas dashboard-icon fa-plus-square',
	// 	buttonText: 'Following',
	// },
	{
		index: 6,
		window: 'connections',
		path: '/connections',
		itemReference: 'connection',
		classNames: 'dashboard-btn',
		icon: 'fas dashboard-icon fa-users',
		buttonText: 'Connections',
	},
	{
		index: 7,
		window: 'discover',
		path: '/user',
		classNames: 'dashboard-btn',
		icon: 'fas dashboard-icon fa-search',
		buttonText: 'Discover People',
	},
	{
		index: 8,
		path: null,
		itemReference: 'log-out',
		classNames: 'dashboard-btn',
		icon: 'fas dashboard-icon fa-sign-out-alt',
		buttonText: 'Log Out',
	},
	{
		index: 9,
		window: 'edit-profile',
		path: '/settings',
		classNames: 'dashboard-btn',
		icon: 'fas dashboard-icon fa-cogs',
		buttonText: 'Settings',
	},
];

import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../event/ToolBar';

const Following = (props) => {
	return (
		<>
			<ToolBar type='following' />
		</>
	);
};

Following.propTypes = {};

export default Following;
